.background-black {
  background: black;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.z-1000 {
  z-index: 1000;
}
