@font-face {
  font-family: 'Adobe-Arabic';
  src: url('~/Adobe Arabic/Adobe Arabic Bold/AdobeArabic-Bold.eot');
  src:
    url('~/Adobe Arabic/Adobe Arabic Bold/AdobeArabic-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Arabic/Adobe Arabic Bold/AdobeArabic-Bold.woff2')
      format('woff2'),
    url('~/Adobe Arabic/Adobe Arabic Bold/AdobeArabic-Bold.woff') format('woff'),
    url('~/Adobe Arabic/Adobe Arabic Bold/AdobeArabic-Bold.ttf')
      format('truetype'),
    url('~/Adobe Arabic/Adobe Arabic Bold/AdobeArabic-Bold.svg#AdobeArabic-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AdobeArabic-BoldItalic';
  src: url('~/Adobe Arabic/Adobe Arabic Bold Italic/AdobeArabic-BoldItalic.eot');
  src:
    url('~/Adobe Arabic/Adobe Arabic Bold Italic/AdobeArabic-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Arabic/Adobe Arabic Bold Italic/AdobeArabic-BoldItalic.woff2')
      format('woff2'),
    url('~/Adobe Arabic/Adobe Arabic Bold Italic/AdobeArabic-BoldItalic.woff')
      format('woff'),
    url('~/Adobe Arabic/Adobe Arabic Bold Italic/AdobeArabic-BoldItalic.ttf')
      format('truetype'),
    url('~/Adobe Arabic/Adobe Arabic Bold Italic/AdobeArabic-BoldItalic.svg#AdobeArabic-BoldItalic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'AdobeArabic-Italic';
  src: url('~/Adobe Arabic/Adobe Arabic Italic/AdobeArabic-Italic.eot');
  src:
    url('~/Adobe Arabic/Adobe Arabic Italic/AdobeArabic-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Arabic/Adobe Arabic Italic/AdobeArabic-Italic.woff2')
      format('woff2'),
    url('~/Adobe Arabic/Adobe Arabic Italic/AdobeArabic-Italic.woff')
      format('woff'),
    url('~/Adobe Arabic/Adobe Arabic Italic/AdobeArabic-Italic.ttf')
      format('truetype'),
    url('~/Adobe Arabic/Adobe Arabic Italic/AdobeArabic-Italic.svg#AdobeArabic-Italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'AdobeArabic-Regular';
  src: url('~/Adobe Arabic/Adobe Arabic Regular/AdobeArabic-Regular.eot');
  src:
    url('~/Adobe Arabic/Adobe Arabic Regular/AdobeArabic-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Arabic/Adobe Arabic Regular/AdobeArabic-Regular.woff2')
      format('woff2'),
    url('~/Adobe Arabic/Adobe Arabic Regular/AdobeArabic-Regular.woff')
      format('woff'),
    url('~/Adobe Arabic/Adobe Arabic Regular/AdobeArabic-Regular.ttf')
      format('truetype'),
    url('~/Adobe Arabic/Adobe Arabic Regular/AdobeArabic-Regular.svg#AdobeArabic-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

/*=============================================*/

@font-face {
  font-family: 'Verdana-Bold';
  src: url('~/verdana-bold/Verdana-Bold.eot');
  src:
    url('~/verdana-bold/Verdana-Bold.eot?#iefix') format('embedded-opentype'),
    url('~/verdana-bold/Verdana-Bold.woff2') format('woff2'),
    url('~/verdana-bold/Verdana-Bold.woff') format('woff'),
    url('~/verdana-bold/Verdana-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Verdana';
  src: url('~/Verdana/Verdana.eot');
  src:
    url('~/Verdana/Verdana.eot?#iefix') format('embedded-opentype'),
    url('~/Verdana/Verdana.woff2') format('woff2'),
    url('~/Verdana/Verdana.woff') format('woff'),
    url('~/Verdana/Verdana.ttf') format('truetype'),
    url('~/Verdana/Verdana.svg#Verdana') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Verdana-BoldItalic';
  src: url('~/Verdana/Verdana-BoldItalic.eot');
  src:
    url('~/Verdana/Verdana-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('~/Verdana/Verdana-BoldItalic.woff2') format('woff2'),
    url('~/Verdana/Verdana-BoldItalic.woff') format('woff'),
    url('~/Verdana/Verdana-BoldItalic.ttf') format('truetype'),
    url('~/Verdana/Verdana-BoldItalic.svg#Verdana-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana-Italic';
  src: url('~/Verdana/Verdana-Italic.eot');
  src:
    url('~/Verdana/Verdana-Italic.eot?#iefix') format('embedded-opentype'),
    url('~/Verdana/Verdana-Italic.woff2') format('woff2'),
    url('~/Verdana/Verdana-Italic.woff') format('woff'),
    url('~/Verdana/Verdana-Italic.ttf') format('truetype'),
    url('~/Verdana/Verdana-Italic.svg#Verdana-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana-Bold';
  src: url('~/Verdana/Verdana-Bold.eot');
  src:
    url('~/Verdana/Verdana-Bold.eot?#iefix') format('embedded-opentype'),
    url('~/Verdana/Verdana-Bold.woff2') format('woff2'),
    url('~/Verdana/Verdana-Bold.woff') format('woff'),
    url('~/Verdana/Verdana-Bold.ttf') format('truetype'),
    url('~/Verdana/Verdana-Bold.svg#Verdana-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

/*================================*/
@font-face {
  font-family: 'Kelvinch';
  src:
    url('~/kelvinch/Kelvinch-Bold.eot?#iefix') format('embedded-opentype'),
    url('~/kelvinch/Kelvinch-Bold.woff2') format('woff2'),
    url('~/kelvinch/Kelvinch-Bold.woff') format('woff'),
    url('~/kelvinch/Kelvinch-Bold.ttf') format('truetype'),
    url('~/kelvinch/Kelvinch-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
/*================================*/

@font-face {
  font-family: 'MyriadPro-Cond';
  src: url('~/Myriad Pro/MyriadPro-BoldSemiExt.eot');
  src:
    url('~/Myriad Pro/MyriadPro-BoldSemiExt.eot?#iefix')
      format('embedded-opentype'),
    url('~/Myriad Pro/MyriadPro-BoldSemiExt.woff2') format('woff2'),
    url('~/Myriad Pro/MyriadPro-BoldSemiExt.woff') format('woff'),
    url('~/Myriad Pro/MyriadPro-BoldSemiExt.ttf') format('truetype'),
    url('~/Myriad Pro/MyriadPro-BoldSemiExt.svg#MyriadPro-BoldSemiExt')
      format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'MyriadPro-BoldCond';
  src:
    url('~/myriadpro/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
    url('~/myriadpro/MyriadPro-BoldCond.woff2') format('woff2'),
    url('~/myriadpro/MyriadPro-BoldCond.woff') format('woff'),
    url('~/myriadpro/MyriadPro-BoldCond.ttf') format('truetype'),
    url('~/myriadpro/MyriadPro-BoldCond.svg#MyriadPro-BoldCond') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'MyriadPro-Bold';
  src: url('~/Myriad Pro/MyriadPro-Bold.eot');
  src:
    url('~/Myriad Pro/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('~/Myriad Pro/MyriadPro-Bold.woff2') format('woff2'),
    url('~/Myriad Pro/MyriadPro-Bold.woff') format('woff'),
    url('~/Myriad Pro/MyriadPro-Bold.ttf') format('truetype'),
    url('~/Myriad Pro/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('~/Myriad Pro/MyriadPro-Regular.eot');
  src:
    url('~/Myriad Pro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('~/Myriad Pro/MyriadPro-Regular.woff2') format('woff2'),
    url('~/Myriad Pro/MyriadPro-Regular.woff') format('woff'),
    url('~/Myriad Pro/MyriadPro-Regular.ttf') format('truetype'),
    url('~/Myriad Pro/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*================================*/
@font-face {
  font-family: 'Adobe-Hebrew';
  src: url('~/Adobe Hebrew/AdobeHebrew-Regular.eot');
  src:
    url('~/Adobe Hebrew/AdobeHebrew-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Hebrew/AdobeHebrew-Regular.woff2') format('woff2'),
    url('~/Adobe Hebrew/AdobeHebrew-Regular.woff') format('woff'),
    url('~/Adobe Hebrew/AdobeHebrew-Regular.ttf') format('truetype'),
    url('~/Adobe Hebrew/AdobeHebrew-Regular.svg#AdobeHebrew-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AdobeHebrew-Bold';
  src: url('~/Adobe Hebrew/AdobeHebrew-Bold.eot');
  src:
    url('~/Adobe Hebrew/AdobeHebrew-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Hebrew/AdobeHebrew-Bold.woff2') format('woff2'),
    url('~/Adobe Hebrew/AdobeHebrew-Bold.woff') format('woff'),
    url('~/Adobe Hebrew/AdobeHebrew-Bold.ttf') format('truetype'),
    url('~/Adobe Hebrew/AdobeHebrew-Bold.svg#AdobeHebrew-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}

/*========================*/
@font-face {
  font-family: 'AdobeDevanagari-Bold';
  src: url('~/Devanagari/AdobeDevanagari-Bold.eot');
  src:
    url('~/Devanagari/AdobeDevanagari-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('~/Devanagari/AdobeDevanagari-Bold.woff2') format('woff2'),
    url('~/Devanagari/AdobeDevanagari-Bold.woff') format('woff'),
    url('~/Devanagari/AdobeDevanagari-Bold.ttf') format('truetype'),
    url('~/Devanagari/AdobeDevanagari-Bold.svg#AdobeDevanagari-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'AdobeDevanagari-Regular';
  src: url('~/Adobe Devanagari/AdobeDevanagari-Regular.eot');
  src:
    url('~/Adobe Devanagari/AdobeDevanagari-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Devanagari/AdobeDevanagari-Regular.woff2') format('woff2'),
    url('~/Adobe Devanagari/AdobeDevanagari-Regular.woff') format('woff'),
    url('~/Adobe Devanagari/AdobeDevanagari-Regular.ttf') format('truetype'),
    url('~/Adobe Devanagari/AdobeDevanagari-Regular.svg#AdobeDevanagari-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AdobeDevanagari-Italic';
  src: url('~/Adobe Devanagari/AdobeDevanagari-Italic.eot');
  src:
    url('~/Adobe Devanagari/AdobeDevanagari-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Devanagari/AdobeDevanagari-Italic.woff2') format('woff2'),
    url('~/Adobe Devanagari/AdobeDevanagari-Italic.woff') format('woff'),
    url('~/Adobe Devanagari/AdobeDevanagari-Italic.ttf') format('truetype'),
    url('~/Adobe Devanagari/AdobeDevanagari-Italic.svg#AdobeDevanagari-Italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AdobeDevanagari-BoldItalic';
  src: url('~/Adobe Devanagari/AdobeDevanagari-BoldItalic.eot');
  src:
    url('~/Adobe Devanagari/AdobeDevanagari-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe Devanagari/AdobeDevanagari-BoldItalic.woff2') format('woff2'),
    url('~/Adobe Devanagari/AdobeDevanagari-BoldItalic.woff') format('woff'),
    url('~/Adobe Devanagari/AdobeDevanagari-BoldItalic.ttf') format('truetype'),
    url('~/Adobe Devanagari/AdobeDevanagari-BoldItalic.svg#AdobeDevanagari-BoldItalic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}
/*===================*/
@font-face {
  font-family: 'DejaVuSans-Bold';
  src:
    url('~/dejavusans/DejaVuSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('~/dejavusans/DejaVuSans-Bold.woff2') format('woff2'),
    url('~/dejavusans/DejaVuSans-Bold.woff') format('woff'),
    url('~/dejavusans/DejaVuSans-Bold.ttf') format('truetype'),
    url('~/dejavusans/DejaVuSans-Bold.svg#DejaVuSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DejaVuSans-BoldOblique';
  src: url('~/DejaVu Sans/DejaVuSans-BoldOblique.eot');
  src:
    url('~/DejaVu Sans/DejaVuSans-BoldOblique.eot?#iefix')
      format('embedded-opentype'),
    url('~/DejaVu Sans/DejaVuSans-BoldOblique.woff2') format('woff2'),
    url('~/DejaVu Sans/DejaVuSans-BoldOblique.woff') format('woff'),
    url('~/DejaVu Sans/DejaVuSans-BoldOblique.ttf') format('truetype'),
    url('~/DejaVu Sans/DejaVuSans-BoldOblique.svg#DejaVuSans-BoldOblique')
      format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DejaVu-Sans';
  src: url('~/DejaVu Sans/DejaVuSans.eot');
  src:
    url('~/DejaVu Sans/DejaVuSans.eot?#iefix') format('embedded-opentype'),
    url('~/DejaVu Sans/DejaVuSans.woff2') format('woff2'),
    url('~/DejaVu Sans/DejaVuSans.woff') format('woff'),
    url('~/DejaVu Sans/DejaVuSans.ttf') format('truetype'),
    url('~/DejaVu Sans/DejaVuSans.svg#DejaVuSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DejaVu-Sans-Condensed';
  src: url('~/DejaVu Sans/DejaVuSansCondensed-BoldOblique.eot');
  src:
    url('~/DejaVu Sans/DejaVuSansCondensed-BoldOblique.eot?#iefix')
      format('embedded-opentype'),
    url('~/DejaVu Sans/DejaVuSansCondensed-BoldOblique.woff2') format('woff2'),
    url('~/DejaVu Sans/DejaVuSansCondensed-BoldOblique.woff') format('woff'),
    url('~/DejaVu Sans/DejaVuSansCondensed-BoldOblique.ttf') format('truetype'),
    url('~/DejaVu Sans/DejaVuSansCondensed-BoldOblique.svg#DejaVuSansCondensed-BoldOblique')
      format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DejaVuSans-Book';
  src: url('~/dejavusans/DejaVuSans.eot');
  src:
    url('~/dejavusans/DejaVuSans.eot?#iefix') format('embedded-opentype'),
    url('~/dejavusans/DejaVuSans.woff2') format('woff2'),
    url('~/dejavusans/DejaVuSans.woff') format('woff'),
    url('~/dejavusans/DejaVuSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/*===================*/
@font-face {
  font-family: 'Adobe 고딕 Std';
  src: url('~/adobestd/AdobeGothicStd-Bold-Alphabetic.eot');
  src:
    url('~/adobestd/AdobeGothicStd-Bold-Alphabetic.eot?#iefix')
      format('embedded-opentype'),
    url('~/adobestd/AdobeGothicStd-Bold-Alphabetic.woff2') format('woff2'),
    url('~/adobestd/AdobeGothicStd-Bold-Alphabetic.woff') format('woff'),
    url('~/adobestd/AdobeGothicStd-Bold-Alphabetic.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/*===================*/
@font-face {
  font-family: 'Chantrea-UI';
  src: url('~/Chantrea UI/ChantreaUI.eot');
  src:
    url('~/Chantrea UI/ChantreaUI.eot?#iefix') format('embedded-opentype'),
    url('~/Chantrea UI/ChantreaUI.woff2') format('woff2'),
    url('~/Chantrea UI/ChantreaUI.woff') format('woff'),
    url('~/Chantrea UI/ChantreaUI.ttf') format('truetype'),
    url('~/Chantrea UI/ChantreaUI.svg#ChantreaUI') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*==============*/
@font-face {
  font-family: 'Loma';
  src: url('~/loma/LomaBold.eot');
  src:
    url('~/loma/LomaBold.eot?#iefix') format('embedded-opentype'),
    url('~/loma/LomaBold.woff2') format('woff2'),
    url('~/loma/LomaBold.woff') format('woff'),
    url('~/loma/LomaBold.ttf') format('truetype'),
    url('~/loma/LomaBold.svg#LomaBold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'LomaBoldOblique';
  src: url('~/loma/LomaBoldOblique.eot');
  src:
    url('~/loma/LomaBoldOblique.eot?#iefix') format('embedded-opentype'),
    url('~/loma/LomaBoldOblique.woff2') format('woff2'),
    url('~/loma/LomaBoldOblique.woff') format('woff'),
    url('~/loma/LomaBoldOblique.ttf') format('truetype'),
    url('~/loma/LomaBoldOblique.svg#LomaBoldOblique') format('svg');
  font-weight: bold;
  font-style: normal;
}

/*================================*/
@font-face {
  font-family: 'HiraginoSansGBW3';
  src: url('~/hiraginosansgbw3/HiraginoSansGBW3.eot');
  src:
    url('~/hiraginosansgbw3/HiraginoSansGBW3.eot?#iefix')
      format('embedded-opentype'),
    url('~/hiraginosansgbw3/HiraginoSansGBW3.woff2') format('woff2'),
    url('~/hiraginosansgbw3/HiraginoSansGBW3.woff') format('woff'),
    url('~/hiraginosansgbw3/HiraginoSansGBW3.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/*================================*/
@font-face {
  font-family: 'AdobeGothic';
  src: url('~/Adobe 고딕 Std B/AdobeGothicStd-Bold-Alphabetic.eot');
  src:
    url('~/Adobe 고딕 Std B/AdobeGothicStd-Bold-Alphabetic.eot?#iefix')
      format('embedded-opentype'),
    url('~/Adobe 고딕 Std B/AdobeGothicStd-Bold-Alphabetic.woff2')
      format('woff2'),
    url('~/Adobe 고딕 Std B/AdobeGothicStd-Bold-Alphabetic.woff') format('woff'),
    url('~/Adobe 고딕 Std B/AdobeGothicStd-Bold-Alphabetic.ttf')
      format('truetype'),
    url('~/Adobe 고딕 Std B/AdobeGothicStd-Bold-Alphabetic.svg#AdobeGothicStd-Bold-Alphabetic')
      format('svg');
  font-weight: bold;
  font-style: normal;
}
/*=============================*/

@font-face {
  font-family: 'Angkor';
  src: url('~/Angkor/Angkor.eot');
  src:
    url('~/Angkor/Angkor.eot?#iefix') format('embedded-opentype'),
    url('~/Angkor/Angkor.woff2') format('woff2'),
    url('~/Angkor/Angkor.woff') format('woff'),
    url('~/Angkor/Angkor.ttf') format('truetype'),
    url('~/Angkor/Angkor.svg#Angkor') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*================*/
@font-face {
  font-family: 'SerpentineBold';
  src:
    url('~/serpentine/SerpentineBold.eot?#iefix') format('embedded-opentype'),
    url('~/serpentine/SerpentineBold.woff2') format('woff2'),
    url('~/serpentine/SerpentineBold.woff') format('woff'),
    url('~/serpentine/SerpentineBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'SerpentineLightOblique';
  src:
    url('~/serpentine/SerpentineLightOblique.eot?#iefix')
      format('embedded-opentype'),
    url('~/serpentine/SerpentineLightOblique.woff2') format('woff2'),
    url('~/serpentine/SerpentineLightOblique.woff') format('woff'),
    url('~/serpentine/SerpentineLightOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/*================*/

@font-face {
  font-family: 'ArialHebrew-Bold';
  src: url('~/arialhebrew-bold/ArialHebrew-Bold.eot');
  src:
    url('~/arialhebrew-bold/ArialHebrew-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('~/arialhebrew-bold/ArialHebrew-Bold.woff2') format('woff2'),
    url('~/arialhebrew-bold/ArialHebrew-Bold.woff') format('woff'),
    url('~/arialhebrew-bold/ArialHebrew-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica-Bold';
  src:
    url('~/helvetica/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
    url('~/helvetica/Helvetica-Bold.woff2') format('woff2'),
    url('~/helvetica/Helvetica-Bold.woff') format('woff'),
    url('~/helvetica/Helvetica-Bold.ttf') format('truetype'),
    url('~/helvetica/Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
/*========================================================*/
@font-face {
  font-family: 'ITC-Zapf-Dingbats';
  src:
    url('~/itczapfdingbats/ITC-Zapf-Dingbats.eot?#iefix')
      format('embedded-opentype'),
    url('~/itczapfdingbats/ITC-Zapf-Dingbats.woff2') format('woff2'),
    url('~/itczapfdingbats/ITC-Zapf-Dingbats.woff') format('woff'),
    url('~/itczapfdingbats/ITC-Zapf-Dingbats.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/*========================================================*/

@font-face {
  font-family: 'Khmer';
  src:
    url('~/khmer/Khmer.eot?#iefix') format('embedded-opentype'),
    url('~/khmer/Khmer.woff2') format('woff2'),
    url('~/khmer/Khmer.woff') format('woff'),
    url('~/khmer/Khmer.ttf') format('truetype'),
    url('~/khmer/Khmer.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
/*========================================================*/

@font-face {
  font-family: 'InaiMathi';
  src: url('~/inaiMathi/InaiMathi.eot');
  src:
    url('~/inaiMathi/InaiMathi.eot?#iefix') format('embedded-opentype'),
    url('~/inaiMathi/InaiMathi.woff2') format('woff2'),
    url('~/inaiMathi/InaiMathi.woff') format('woff'),
    url('~/inaiMathi/InaiMathi.ttf') format('truetype'),
    url('~/inaiMathi/InaiMathi.svg#InaiMathi') format('svg');
  font-weight: normal;
  font-style: normal;
}
