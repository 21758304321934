/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '../src/styles/shared/base/helpers';

body {
  background-color: black;
  overflow-y: hidden;
  -ms-overflow-y: hidden;
  -webkit-overflow-scrolling: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
#main:fullscreen {
  overflow: scroll !important;
}
#main:-ms-fullscreen {
  overflow: scroll !important;
}
#main:-webkit-full-screen {
  overflow: scroll !important;
}
#main:-moz-full-screen {
  overflow: scroll !important;
}
html,
body {
  margin: 0 !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.bg-home {
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/common/damask/damask_1200x1200.webp');
  background-repeat: repeat;
  background-color: black;
}
.fc21-damask {
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/common/damask/green_damask.webp');
  background-repeat: repeat;
}
.fcb-damask {
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/common/damask/red_damask.webp');
  background-repeat: repeat;
}
.bgfc21 {
  width: 100%;
  height: 100%;
  background: #113d1e; /*
  background: -moz-linear-gradient(top, #113d1e 0%, #18542d 43%, #113d1e 100%);
  background: -webkit-linear-gradient(top, #113d1e 0%, #18542d 43%, #113d1e 100%);
  background: linear-gradient(to bottom, #113d1e 0%, #18542d 43%, #113d1e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#113d1e', endColorstr = '#113d1e', GradientType = 0);*/
}

.bgfcb {
  width: 100%;
  height: 100%;
  background: #480a06;
  /*background: -moz-linear-gradient(top, #480a06 0%, #a20003 43%, #480a06 100%);
  background: -webkit-linear-gradient(top, #480a06 0%, #a20003 43%, #480a06 100%);
  background: linear-gradient(to bottom, #480a06 0%, #a20003 43%, #480a06 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#480a06', endColorstr='#480a06', GradientType=0);*/
}

.bghistoryfc21 {
  background: #e1ffe7;
  width: 100%;
  height: 100%;
}

.bghistoryfc21mobile {
  overflow-y: scroll;
  background: #113d1e;
  width: 100vw;
  height: 100vh;
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
}

.modal-backdrop {
  display: none;
}

#dummy-overlay,
#dummy-overlay-1 {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
}

* {
  margin: 0;
  padding: 0;
  outline: none !important;
}

.ui-widget {
  font-size: 18px !important;
}

.ng-tooltip {
  position: fixed;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  background-image: linear-gradient(#fefefe 20%, #8e8982 100%);
  font-family: verdana;
  color: #113d1e;
  font-weight: bold;
  white-space: nowrap;
  /*Based on responsive style value change 'ng-tooltip-style'*/
  padding: 0;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 0.3vw;
  font-size: 1vw;
}
.ng-tooltip_lobbymenu {
  position: absolute;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  background-image: linear-gradient(#fefefe 20%, #8e8982 100%);
  font-family: verdana;
  color: #113d1e;
  font-weight: bold;
  white-space: nowrap;
  /*Based on responsive style value change 'ng-tooltip-style'*/
  padding: 0;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 0.3vw;
  font-size: 0.7vw;
}
.ng-tooltip-show {
  opacity: 1;
}
@media all and (display-mode: fullscreen) {
  .ng-tooltip_lobbymenu {
    position: fixed;
  }
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .ui-widget {
    font-size: 22px !important;
  }
}

@media screen and (min-width: 2000px) {
  .ui-widget {
    font-size: 26px !important;
  }
}

html,
body {
  height: max-content !important;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.RUB-text-content-string {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica,Arial', sans-serif !important;
}
.INR-text-content-string-h {
  /*font-family: Kelvinch !important;*/
  font-family: 'Verdana' !important;
}
.GEL-text-content-string {
  /*font-family: Kelvinch !important;*/
  font-family: 'DejaVuSans-Bold' !important;
}
.AMD-text-content-string {
  /*font-family: Kelvinch !important;*/
  font-family: 'DejaVuSans-Bold' !important;
}
.BTC-bet-amount-container {
  font-size: 9px !important;
}
.BTC-double-event-amount {
  font-size: 9px !important;
}
.BTC-insurance-event-amount {
  font-size: 9px !important;
}
.MBTC-bet-amount-container {
  font-size: 9px !important;
}
.MBTC-double-event-amount {
  font-size: 9px !important;
}
.MBTC-insurance-event-amount {
  font-size: 9px !important;
}
@media screen and (min-width: 2000px) {
  .BTC-bet-amount-container {
    font-size: 18px !important;
  }
  .BTC-double-event-amount {
    font-size: 18px !important;
  }
  .BTC-insurance-event-amount {
    font-size: 18px !important;
  }
  .MBTC-bet-amount-container {
    font-size: 18px !important;
  }
  .MBTC-double-event-amount {
    font-size: 18px !important;
  }
  .MBTC-insurance-event-amount {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .BTC-bet-amount-container {
    font-size: 12px !important;
  }
  .BTC-double-event-amount {
    font-size: 12px !important;
  }
  .BTC-insurance-event-amount {
    font-size: 12px !important;
  }
  .MBTC-bet-amount-container {
    font-size: 12px !important;
  }
  .MBTC-double-event-amount {
    font-size: 12px !important;
  }
  .MBTC-insurance-event-amount {
    font-size: 12px !important;
  }
}

.ar-lang-specific-font-name {
  font-family: Adobe-Arabic !important;
  direction: rtl !important;
}

.bg-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}

.cs-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}

.da-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}

.de-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.ee-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.en-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.es-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.fi-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.fr-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.ge-lang-specific-font-name {
  font-family: DejaVuSans-Bold !important;
}
.gr-lang-specific-font-name {
  font-family: MyriadPro-BoldCond !important;
}
.he-lang-specific-font-name {
  font-family: Adobe-Hebrew !important;
  direction: rtl !important;
}
.hi-IN-lang-specific-font-name {
  font-family: AdobeDevanagari-Bold !important;
}
.hu-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.hy-lang-specific-font-name {
  font-family: DejaVuSans-Bold !important;
}
.id-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.it-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.ja-lang-specific-font-name {
  font-family: Adobe 고딕 Std !important;
}
.kh-lang-specific-font-name {
  font-family: Chantrea-UI !important;
}
.ko-lang-specific-font-name {
  font-family: Adobe 고딕 Std !important;
}
.lt-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.lv-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.mt-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.my-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.nb-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.nl-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.ph-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.pl-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.pt-BR-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.ro-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.ru-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.si-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.sk-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.sr-RS-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.sv-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.ta-IN-lang-specific-font-name {
  font-family: Verdana-Bold !important;
}
.th-lang-specific-font-name {
  font-family: Loma !important;
}
.tr-lang-specific-font-name {
  font-family: MyriadPro-BoldCond !important;
}
.ua-lang-specific-font-name {
  font-family: MyriadPro-BoldCond !important;
}
.vi-lang-specific-font-name {
  font-family: MyriadPro-BoldCond !important;
}
.zh-Hans-lang-specific-font-name {
  font-family: HiraginoSansGBW3 !important;
}
.zh-Hant-lang-specific-font-name {
  font-family: HiraginoSansGBW3 !important;
}
.Push-gameResult {
  background-color: yellow;
}
.Won-gameResult {
  background-color: #06f610;
}
.Lost-gameResult {
  background-color: red;
}
#popup_makeCenter {
  position: fixed;
  display: grid;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  align-content: center;
  align-items: center;
  text-align: center;
}
#popup_makeCenter_close {
  position: fixed;
  display: grid;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*  background-color: rgba(0, 0, 0, 0.9); */
  z-index: 1000;
  align-content: center;
  align-items: center;
  text-align: center;
}
@media (orientation: landscape) {
  .vip_bg_viasino {
    top: -68px !important;
    transform: scale(0.8);
  }
}

@media (orientation: portrait) {
  .vip_bg_viasino {
    top: -70px !important;
  }
}
/* ---------------------------------------------- */
/* img {
  //transform: translateZ(0) scale(0.999999);
  image-rendering: -webkit-optimize-contrast !important;
  } */

/*  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
  img {
    image-rendering: -webkit-optimize-contrast !important;
  }
}

 //Unset for Safari 11+
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  img {
    image-rendering: unset !important;
  }
}} */
.lauchLiveDealer_class {
  position: absolute;
  width: 10vw;
  height: 5vw;
  top: 0vw;
  left: 0vw;
  z-index: 100;
}
